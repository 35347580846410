import { Flex, Text, Link } from "@chakra-ui/layout";
import React from "react";
import MainHeader from "./MainHeader";

const NotFound = () => {
  return (
    <Flex direction="column" flex="1">
      <MainHeader variant="dashboard" />
      <Flex as="main" role="main" direction="column" flex="1">
        <Flex
          direction="column"
          bg="#ebe8f1"
          padding="16px"
          fontFamily="Garamond"
          margin="16px"
          textAlign="center"
          justifyContent="center"
          h="100%"
        >
          <Text fontSize="6xl" fontWeight="bold">
            404
          </Text>
          <Text>
            Oops! This page doesn't exist. Don't worry, mistakes happen.
          </Text>
          <Text>
            You can either go
            <Link color="teal" href="/">
              {" "}
              back home{" "}
            </Link>
            or
            <Link color="teal" href="/dashboard">
              {" "}
              back to dashboard
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NotFound;
