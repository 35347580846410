import React from "react";
import { Avatar, HStack, Image, Stack, Text } from "@chakra-ui/react";

const Toronto = () => {
  return (
    <Stack
      bg="#E4E6EDcc"
      padding="32px"
      fontFamily="Garamond"
      fontSize="xl"
      marginTop="8px"
      alignItems="flex-start"
      spacing={3}
      backgroundImage="/toronto.jpg"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundBlendMode="lighten"
    >
      <Stack
        backgroundColor="rgba(255,255,255,0.5)"
        padding="16px"
        alignItems="center"
        spacing={4}
      >
        <Stack direction={{ base: "column", lg: "row" }} alignItems="center">
          <Stack>
            <Text fontWeight="bold" fontSize="3xl">
              Greater Toronto and Hamilton Area
            </Text>
            <Text>
              Very vibrant cities - the greater Toronto area boasts more than 5
              million people and is dense with work opportunities and reputable
              universities. If you like a faster pace of life the GTA is a place
              you will thrive. It also has some of the best transportation
              system in Canada.
            </Text>
            <Text>
              Hamilton on the other hand is a bus ride from Toronto and is home
              to important universities. It is a smaller city but it is
              culturally diverse and in great proximity to key resources to aid
              you to settle down. The cultural diversity in Ontario as a
              province is unparalleled across Canada. So if you have decided to
              move here, great choice!
            </Text>
            <Text>
              Here are some free resources to check out in the mean time:
            </Text>
          </Stack>
          <Image
            src="/toronto2.jpg"
            alt="Toronto picture"
            w="450px"
            flex="1"
            display={{ base: "none", lg: "inherit" }}
          />
        </Stack>
        <HStack justifyContent="center" w="100%">
          <Avatar
            as="a"
            name="INS"
            size="2xl"
            src="/settlementorg.png"
            href="https://settlement.org/"
            target="_blank"
          />
          <Avatar
            as="a"
            name="ISSBC"
            size="2xl"
            src="/ymcagta.png"
            href="https://www.ymcagta.org/immigrant-services"
            target="_blank"
          />
        </HStack>
        <Image
          src="/toronto2.jpg"
          alt="Toronto picture"
          w="100%"
          flex="1"
          display={{ base: "inherit", lg: "none" }}
        />
      </Stack>
    </Stack>
  );
};

export default Toronto;
