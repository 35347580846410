import { Card, CardBody, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import Flag from "react-world-flags";

const Review = (props) => {
  return (
    <Card maxW="xlg" margin="8px">
      <CardBody>
        <Flex direction="column" alignItems="center">
          <Text
            fontFamily="Cardo"
            fontSize="md"
            noOfLines={props.full ? null : 4}
          >
            {props.feedback}
          </Text>
          <Text fontFamily="Cardo" fontSize="sm" fontStyle="italic">
            ({props.type})
          </Text>
          <Heading size="sm">
            {props.name}, {props.year}
          </Heading>
          <Flag width="50px" code={props.country} />
        </Flex>
      </CardBody>
    </Card>
  );
};

export default Review;
