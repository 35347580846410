import React, { useState, useEffect } from "react";
import { Card, Stack, Text } from "@chakra-ui/react";

const bubbles = [
  {
    origin: "client",
    msg: "Hi Victoria, my name is Nandina. I am moving to Toronto Canada in January 2025. How can Raven - Support Network help me?",
  },
  {
    origin: "server",
    msg: "Hi Nandina, this is amazing news! Thank you for reaching out and congratulations!\nThere’s so many ways we can help you but before anything else, I want to hear your story. Can we hop on a call?",
  },
  {
    origin: "client",
    msg: "Absolutely! Wait. Are your services free?",
  },
  {
    origin: "server",
    msg: "The call is free - definitely and so many other services included are free. Some services provided come at an additional costs and are fully hands-on! Let’s connect and I can tell you more, what do you say? ",
  },
  {
    origin: "client",
    msg: "Yes, let’s do it. I’m excited and nervous at the same time. It’s so much to think about organizing this move.",
  },
  {
    origin: "server",
    msg: "I have been there and know the feeling. That’s why RSN is here. Well, Nandina I can’t wait to connect with you! I’ll call you tomorrow and we can discuss further. Yes?",
  },
  {
    origin: "client",
    msg: "Let’s do it! Thank you!!",
  },
];

const Conversation = () => {
  const containerRef = React.useRef(null);
  const [index, setIndex] = useState(0);
  const [showDelivered, setShowDelivered] = useState(-1);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => {
        const newState = (state + 1) % bubbles.length;
        if (newState === 0) setShowDelivered(-1);
        else if (bubbles[newState].origin === "server")
          setShowDelivered(newState);
        return newState;
      });
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack
      padding="16px"
      position="absolute"
      top="17%"
      left="2%"
      right="2%"
      h="72%"
      justifyContent="flex-end"
      overflowY="hidden"
      ref={containerRef}
    >
      {bubbles.map((bubble, i) => (
        <Stack key={`bubble${i}`} spacing={0.1}>
          <Card
            color={bubble.origin === "client" ? "black" : "white"}
            bg={bubble.origin === "client" ? "#d8d8d8" : "#218aff"}
            fontSize={{ base: "sm", md: "md" }}
            variant="filled"
            maxW="175px"
            padding="8px"
            alignSelf={bubble.origin === "client" ? "flex-start" : "flex-end"}
            hidden={i > index}
          >
            {bubble.msg}
          </Card>
          {i === showDelivered ? (
            <Text alignSelf="flex-end" fontSize="xs">
              Delivered
            </Text>
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};

export default Conversation;
