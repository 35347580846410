import {
  Box,
  Button,
  // Checkbox,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Alert,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "./Logo";
import {
  forgotPassword,
  forgotPasswordSubmit,
  signIn,
} from "../functions/auth";
import { Field, Form, Formik } from "formik";
import { validateForm } from "../functions/validations";
import PasswordField from "./PasswordField";
import { useNavigate } from "react-router-dom";
import InConstruction from "./InConstruction"
const Login = () => {
  const [loginError, setLoginError] = useState();
  const [loginState, setLoginState] = useState("login");
  const [savedEmail, setSavedEmail] = useState("");

  const texts = {
    login: {
      prompt: "",
      button: "Sign In",
      form: { email: "", password: "" },
    },
    "forgot-init": {
      prompt:
        "Please enter your email to reset your password, we will send you an access code",
      button: "Submit",
      form: { email: "" },
    },
    "forgot-reset": {
      prompt: "Enter access code and new password",
      button: "Reset Password",
      form: { email: savedEmail, password: "", code: "" },
    },
  };

  const navigate = useNavigate();
  return (
    <InConstruction />
    // <Container
    //   maxW="lg"
    //   py={{ base: "12", md: "24" }}
    //   px={{ base: "0", sm: "8" }}
    // >
    //   <Stack spacing="8">
    //     <Stack spacing="6" alignItems="center">
    //       <Logo w="100px" />
    //       <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
    //         <Heading size={{ base: "xs", md: "sm" }}>Welcome</Heading>
    //       </Stack>
    //     </Stack>
    //     <Box
    //       py={{ base: "0", sm: "8" }}
    //       px={{ base: "4", sm: "10" }}
    //       bg={{ base: "transparent", sm: "bg.surface" }}
    //       boxShadow={{ base: "none", sm: "md" }}
    //       borderRadius={{ base: "none", sm: "xl" }}
    //     >
    //       {loginError ? (
    //         <Alert status="error" fontSize="lg">
    //           {loginError}
    //         </Alert>
    //       ) : null}
    //       <Formik
    //         enableReinitialize
    //         initialValues={texts[loginState].form}
    //         validate={validateForm}
    //         onSubmit={async (values, actions) => {
    //           if (loginState === "forgot-init") {
    //             try {
    //               setLoginError();
    //               await forgotPassword(values.email);
    //               actions.setSubmitting(false);
    //               setSavedEmail(values.email);
    //               setLoginState("forgot-reset");
    //             } catch (error) {
    //               actions.setSubmitting(false);
    //               setLoginError(error.message);
    //             }
    //           } else {
    //             try {
    //               setLoginError();
    //               let user;
    //               if (loginState === "login") {
    //                 user = await signIn(values.email, values.password);
    //               } else {
    //                 user = await forgotPasswordSubmit(
    //                   values.email,
    //                   values.code,
    //                   values.password
    //                 );
    //               }
    //               // console.log(user);
    //               actions.setSubmitting(false);
    //               if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    //                 navigate("/signup");
    //               } else navigate("/dashboard");
    //             } catch (error) {
    //               actions.setSubmitting(false);
    //               setLoginError(error.message);
    //             }
    //           }
    //         }}
    //       >
    //         {(props) => (
    //           <Form>
    //             <Stack spacing="6">
    //               <Text color="fg.muted" fontSize="md">
    //                 {texts[loginState].prompt}
    //               </Text>
    //               <Stack spacing="5">
    //                 <Field name="email" type="email">
    //                   {({ field, form }) => (
    //                     <FormControl
    //                       isRequired
    //                       isInvalid={form.errors.email && form.touched.email}
    //                     >
    //                       <FormLabel htmlFor="email">Email</FormLabel>
    //                       <Input
    //                         {...field}
    //                         placeholder="Email"
    //                         value={savedEmail !== "" ? savedEmail : undefined}
    //                       />
    //                       <FormErrorMessage>
    //                         {form.errors.email}
    //                       </FormErrorMessage>
    //                     </FormControl>
    //                   )}
    //                 </Field>
    //                 {loginState === "forgot-reset" ? (
    //                   <Field name="code">
    //                     {({ field, form }) => (
    //                       <FormControl
    //                         isRequired
    //                         isInvalid={form.errors.code && form.touched.code}
    //                       >
    //                         <FormLabel>Access Code</FormLabel>
    //                         <Input {...field} placeholder="Code" />
    //                         <FormErrorMessage>
    //                           {form.errors.code}
    //                         </FormErrorMessage>
    //                       </FormControl>
    //                     )}
    //                   </Field>
    //                 ) : null}
    //                 {loginState !== "forgot-init" ? <PasswordField /> : null}
    //               </Stack>
    //               <HStack justify="space-between">
    //                 {/* <Checkbox defaultChecked>Remember me</Checkbox> */}
    //                 {loginState === "login" ? (
    //                   <Button
    //                     variant="text"
    //                     size="sm"
    //                     onClick={() => setLoginState("forgot-init")}
    //                   >
    //                     Forgot password?
    //                   </Button>
    //                 ) : null}
    //               </HStack>
    //               <Button type="submit" isLoading={props.isSubmitting}>
    //                 {texts[loginState].button}
    //               </Button>
    //             </Stack>
    //           </Form>
    //         )}
    //       </Formik>
    //     </Box>
    //   </Stack>
    // </Container>
  );
};

export default Login;
