import React from "react";
import { Avatar, HStack, Image, Stack, Text } from "@chakra-ui/react";

const Winnipeg = () => {
  return (
    <Stack
      bg="#fdf9e1cc"
      padding="32px"
      fontFamily="Garamond"
      fontSize="xl"
      marginTop="8px"
      alignItems="flex-start"
      spacing={3}
      backgroundImage="/winnipeg.jpg"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundBlendMode="lighten"
    >
      <Stack
        backgroundColor="rgba(255,255,255,0.5)"
        padding="16px"
        alignItems="center"
        spacing={4}
      >
        <Stack direction={{ base: "column", lg: "row" }} alignItems="center">
          <Stack>
            <Text fontWeight="bold" fontSize="3xl">
              Winnipeg
            </Text>
            <Text>
              This is a beautiful city and community to begin life in Canada.
              Located within the province of Manitoba, a prairie beauty,
              Winnipeg is the capital of Manitoba and welcomes a large number of
              immigrants and migrants every year. With a population that’s under
              2 million as of 2024, you will encounter a mix of people from all
              across the world.
            </Text>
            <Text>
              Whether you are immigrating to work or study, the South of
              Winnipeg boasts great options for housing, recreation, community
              building and he reputable University of Manitoba.
            </Text>
            <Text>
              Winnipeg is famous for being affordable, and truly it is one of
              the more affordable cities to live in Canada.
            </Text>
            <Text>Check out the following resources in Winnipeg:</Text>
          </Stack>
          <Image
            src="/winnipeg2.jpg"
            alt="Winnipeg picture"
            w="450px"
            flex="1"
            display={{ base: "none", lg: "inherit" }}
          />
        </Stack>
        <HStack justifyContent="center" w="100%">
          <Avatar
            as="a"
            name="YES"
            size="2xl"
            src="/yes.png"
            href="https://yesmb.ca"
            target="_blank"
          />
          <Avatar
            as="a"
            name="YES"
            size="2xl"
            src="/ircom.png"
            href="https://ircom.ca/"
            target="_blank"
          />
          <Avatar
            as="a"
            name="IC"
            size="2xl"
            src="/icm.jpeg"
            href="https://www.icmanitoba.com/"
            target="_blank"
          />
        </HStack>
        <Image
          src="/winnipeg2.jpg"
          alt="Winnipeg picture"
          w="100%"
          flex="1"
          display={{ base: "inherit", lg: "none" }}
        />
      </Stack>
    </Stack>
  );
};

export default Winnipeg;
