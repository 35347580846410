import { Flex, HStack, Text, Stack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import MainHeader from "../MainHeader";
import { Button } from "@chakra-ui/button";
import Flag from "react-world-flags";
import { userFromApi } from "../../functions/parsings";
import { quotes } from "../../functions/api";
import MainDashboard from "./MainDashboard";
import InConstruction from "../InConstruction";
import SimpleSidebar from "../Sidebar";

const mainOption = {
  name: "main",
  navigate: "/main",
};
const options = [
  {
    name: "Accommodation",
    navigate: "/accommodation",
    items: [
      "Tenancy Laws",
      "Top 5 Important Things: On campus or Off Campus",
      "Don’t pay anything until you ask these questions",
      "Life-style Impact of your accommodation",
    ],
  },
  {
    name: "Community",
    navigate: "/community",
    items: [
      "Building a community organically",
      "Relational Flags to observe",
      "Holidays look different now",
      "Finding food you like",
      "Commuting",
    ],
  },
  {
    name: "Finances",
    navigate: "/finances",
    items: [
      "Banking is fun",
      "Budgeting and templates",
      "Sending and receiving money",
      "Credit card",
      "Finding a job",
    ],
  },
  {
    name: "Self-Care",
    navigate: "/self-care",
    items: ["Inexpensive Fun", "Staying connected", "Stress", "Identity"],
  },
  {
    name: "Travel",
    navigate: "/travel",
    items: [
      "Checklists before you depart your home country",
      "Places to visit in Canada",
      "Get your USA Visa",
      "Travel with a Raven partner",
    ],
  },
];
const PanelView = ({ selected, user, quote, loading }) => {
  switch (selected.navigate) {
    case "/main":
      return (
        <MainDashboard
          permitExpiry={user.permitExpiry}
          arrivalDate={user.arrivalDate}
          quote={quote}
          loadQuote={loading}
        />
      );
    case "/accommodation":
    case "/community":
    case "/finances":
    case "/self-care":
    case "/travel":
      return (
        <SimpleSidebar items={selected.items}>
          <InConstruction withHeader={false} />
        </SimpleSidebar>
      );
    default:
      return <InConstruction withHeader={false} />;
  }
};

const UserDashboard = ({ userAttr }) => {
  const user = userFromApi(userAttr.attributes);
  const [selected, setSelected] = useState(mainOption);
  const [quote, setQuote] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    quotes()
      .then((res) => {
        setLoading(false);
        setQuote(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setQuote("Your accent does not determine your intelligence.");
      });
  }, [quote]);

  return (
    <Flex direction="column" flex="1">
      <MainHeader variant="dashboard" />
      <Flex as="main" role="main" direction="column" flex="1">
        <Flex
          direction="column"
          bg="#ebe8f1"
          padding="16px"
          fontFamily="Garamond"
          margin="16px"
          textAlign="center"
        >
          <HStack w="100%" justifyContent="center" position="relative">
            <Stack position="absolute" top="0" left="0">
              <Flag width="80px" code={user.countryOrgCode} />
            </Stack>
            <Stack>
              <Text fontSize="4xl" fontWeight="bold">
                Dear {user.firstName},
              </Text>
              <Text fontSize="3xl">Welcome to the community!</Text>
              <Text fontSize="3xl">Let's talk...</Text>
            </Stack>
          </HStack>
        </Flex>
        <HStack
          justifyContent="space-between"
          paddingLeft="16px"
          paddingRight="16px"
        >
          {options.map((option, i) => (
            <Button
              key={i}
              bgColor={
                option.navigate === selected.navigate ? "#bfb3c0" : "#f8e5e6"
              }
              _hover={{ bg: "#bfb3c0" }}
              w="160px"
              onClick={() => {
                if (option.navigate === selected.navigate)
                  setSelected(mainOption);
                else setSelected(option);
              }}
            >
              {option.name}
            </Button>
          ))}
        </HStack>
        <PanelView
          selected={selected}
          user={user}
          quote={quote}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default UserDashboard;
