export function validateForm(values) {
  console.log(values)
  const errors = {};
  if (isEmpty(values.email)) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (isEmpty(values.password)) {
    errors.password = "Password is required";
  }
  if (isEmpty(values.firstName)) {
    errors.firstName = "Required";
  }
  if (isEmpty(values.lastName)) {
    errors.lastName = "Required";
  }
  if (isEmpty(values.country)) {
    errors.country = "Please select country";
  }
  if (isEmpty(values.code)) {
    errors.code = "Access code is required";
  }
  return errors;
}

function isEmpty(value) {
  return value !== undefined && value === "";
}
