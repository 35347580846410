import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";

export default function SimpleSidebar({ items, children }) {
  return (
    <Box h="100%" margin="16px" bg={useColorModeValue("#fef7ff", "#fef7ff")}>
      <SidebarContent items={items} />
      <Box ml="80" p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ items, ...rest }) => {
  return (
    <Box
      bg={useColorModeValue("#ebdbea", "#fef7ff")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w="80"
      pos="fixed"
      h="full"
      {...rest}
    >
      {items.map((link) => (
        <NavItem key={link}>{link}</NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ children, ...rest }) => {
  return (
    <Box as="a" href="#" _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        fontSize="xl"
        _hover={{
          color: "#953a90",
        }}
        {...rest}
      >
        {children}
      </Flex>
    </Box>
  );
};
