import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import MainHeader from "./MainHeader";

const InConstruction = ({ withHeader }) => {
  return (
    <Flex direction="column" flex="1">
      {withHeader ?? <MainHeader variant="dashboard" />}
      <Flex as="main" role="main" direction="column" flex="1">
        <Flex
          direction="column"
          bg="#ebe8f1"
          padding="16px"
          fontFamily="Garamond"
          margin="16px"
          textAlign="center"
          justifyContent="center"
          h="100%"
        >
          <Text fontWeight="bold">This page is under construction</Text>
          <Text>More things coming up very soon, stay tuned!</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InConstruction;
