import React, { useState } from "react";
import {
  Button,
  Flex,
  Text,
  Stack,
  Image,
  Card,
  CardBody,
  Icon,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import {
  FcBriefcase,
  FcClock,
  FcConferenceCall,
  FcCurrencyExchange,
  FcHome,
  FcTodoList,
} from "react-icons/fc";
import { useTransition, animated } from "@react-spring/web";

const slides = [
  { name: "Winnipeg", route: "/winnipeg", img: "/winnipeg.jpg" },
  {
    name: "Greater Toronto and Hamilton Area",
    route: "/toronto",
    img: "/toronto.jpg",
  },
  { name: "Metro Vancouver", route: "/vancouver", img: "/vancouver.jpg" },
];

const Information = () => {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 50, tension: 50, friction: 50 },

    onRest: (_a, _b, item) => {
      if (index === item) {
        setIndex((state) => (state + 1) % slides.length);
      }
    },
  });

  return (
    <Flex direction="column" height="100%">
      <MainHeader variant="info" />
      <Flex as="main" role="main" direction="column" flex="1">
        <Stack
          bg="#ebe8f1"
          fontFamily="Garamond"
          marginTop="8px"
          height="calc(100vh - 100px)"
          onClick={() => navigate("/cities")}
        >
          {transitions((style, key) => (
            <animated.div
              key={key}
              style={{
                ...style,
                display: "flex",
                flexDirection: "column",
                padding: "32px",
                height: "calc(100vh - 100px)",
                width: "100%",
                position: "absolute",
                willChange: "opacity",
                backgroundImage: `url(${slides[key].img})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                justifyContent: "space-between",
                alignItems: "self-start",
              }}
            >
              <Text fontSize="5xl">Find us in</Text>
              <Stack>
                {slides.map((item, i) => (
                  <Button key={`cityButton${i}`} isActive={i === index}>
                    {item.name}
                  </Button>
                ))}
              </Stack>
              <Button alignSelf="flex-end" onClick={() => navigate("/cities")}>
                <Text
                  fontWeight="bold"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Click here for more info!
                </Text>
              </Button>
            </animated.div>
          ))}
        </Stack>
        <Stack
          justifyContent="center"
          alignItems="center"
          bg="#ebe8f1"
          padding="32px"
          fontFamily="Garamond"
          marginTop="8px"
          textAlign="center"
          height="calc(100vh - 100px)"
          spacing="4"
        >
          <Text>Are you moving to Canada in January 2025?</Text>
          <Text>Have you gotten your Canadian visa?</Text>
          <Button
            as="a"
            colorScheme="yellow"
            href="https://calendly.com/vcnng/raven-support-network"
            target="_blank"
            h="fit-content"
          >
            <Text
              fontSize="4xl"
              fontWeight="bold"
              whiteSpace="normal"
              wordBreak="break-word"
            >
              Join our January 2025 Cohort of Newcomers*
            </Text>
          </Button>
          <Text fontSize="sm" fontWeight="bold" marginTop="-8px">
            *only 12 spots available
          </Text>
        </Stack>
        <Flex
          justifyContent="center"
          alignItems="center"
          backgroundImage="/IMG_9459.JPG"
          backgroundSize="cover"
          backgroundPosition="center center"
          fontFamily="Garamond"
          padding="32px"
          marginTop="8px"
          height="calc(100vh - 100px)"
        >
          <Flex
            bg="rgba(239,239,239,0.75)"
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding="16px"
          >
            <Text>Be Intentional About Your Migrant Journey</Text>
            <Button
              as="a"
              colorScheme="yellow"
              target="_blank"
              marginTop="8px"
              onClick={() => navigate("/about")}
            >
              Learn More
            </Button>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          bg="#EFEFEF"
          fontFamily="Garamond"
          padding="32px"
          marginTop="8px"
        >
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={{ base: "8", lg: "16" }}
            alignItems="center"
          >
            <Image
              src="/friends-in-computer.jpg"
              alt="Friends around computer"
              w={{ base: "200px", lg: "250px" }}
              flex="1"
              animation="fadeIn 5s"
            />
            <Stack alignSelf="center">
              <Text fontWeight="bold" fontSize="3xl">
                Our Services
              </Text>
              <SimpleGrid
                spacing={8}
                fontSize="lg"
                columns={{ base: 2, md: 3 }}
              >
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcClock} />
                      <Text fontWeight="bold">Moving Support</Text>
                    </HStack>
                    <Text>Prepare for before, during and after your move</Text>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcBriefcase} />
                      <Text fontWeight="bold">Employment Aid</Text>
                    </HStack>
                    <Text>
                      Benefit from tools and resources to succeed in your career
                      abroad
                    </Text>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcCurrencyExchange} />
                      <Text fontWeight="bold">Financial Guidance</Text>
                    </HStack>
                    <Text>Learn about key information about your finances</Text>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcConferenceCall} />
                      <Text fontWeight="bold"> Community Connections</Text>
                    </HStack>
                    <Text>
                      Connect and integrate with locals and other immigrants
                    </Text>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcHome} />
                      <Text fontWeight="bold">Search for Housing</Text>
                    </HStack>
                    <Text>
                      Discover and get to know your home before arriving
                    </Text>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <HStack>
                      <Icon as={FcTodoList} />
                      <Text fontWeight="bold">Settlement Assistance</Text>
                    </HStack>
                    <Text>
                      Weekly meetings to prepare you for your new life in Canada
                    </Text>
                  </CardBody>
                </Card>
              </SimpleGrid>
            </Stack>
          </Stack>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Information;
