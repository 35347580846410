import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Select,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "./Logo";
import { Field, Form, Formik } from "formik";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { validateForm } from "../functions/validations";
import PasswordField from "./PasswordField";
import { signUp } from "../functions/auth";
import { accessCode, getCountries } from "../functions/api";
import { useNavigate } from "react-router-dom";

const SignupCode = ({ setSignupFlow, setUserForm }) => {
  const [codeError, setCodeError] = useState();

  return (
    <Stack>
      {codeError ? (
        <Alert status="error" fontSize="lg">
          {codeError}
        </Alert>
      ) : null}

      <Formik
        initialValues={{
          email: "",
          code: "",
        }}
        validate={(values) => validateForm(values)}
        onSubmit={async (values, actions) => {
          try {
            setCodeError();
            const user = await accessCode(values.email, values.code);
            setUserForm(user.data);
            setSignupFlow("signup");
            actions.setSubmitting(false);
          } catch (err) {
            actions.setSubmitting(false);
            setCodeError(err.response.data.message);
          }
        }}
      >
        {(props) => (
          <Form>
            <Stack spacing="5">
              <Field name="email" type="email">
                {({ field, form }) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input {...field} placeholder="Email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="code">
                {({ field, form }) => (
                  <FormControl
                    isRequired
                    isInvalid={form.errors.code && form.touched.code}
                  >
                    <FormLabel>Access Code</FormLabel>
                    <Input {...field} placeholder="Code" />
                    <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button type="submit" isLoading={props.isSubmitting}>
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

const SignupForm = ({ userForm, setSignupFlow }) => {
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [signupError, setSignupError] = useState();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState(null);

  if (!countries) {
    getCountries().then((resp) => {
      let ordered = resp.data;
      ordered.sort(function (a, b) {
        if (a.name.common < b.name.common) return -1;
        if (a.name.common > b.name.common) return 1;
        return 0;
      });
      setLoading(false);
      setCountries(resp.data);
    });
  }

  return (
    <Stack spacing="6">
      {loading ? (
        <CircularProgress isIndeterminate />
      ) : (
        <React.Fragment>
          {signupError ? (
            <Alert status="error" fontSize="lg">
              {signupError}
            </Alert>
          ) : null}
          <Formik
            initialValues={{
              email: userForm.email,
              firstName: userForm.firstName,
              lastName: userForm.lastName,
              arrival: arrivalDate,
              permitExpiry: expiryDate,
              country: "",
              password: "",
            }}
            validate={(values) => validateForm(values)}
            onSubmit={async (values, actions) => {
              try {
                const countryName = countries[values.country].name.common;
                const countryCode = countries[values.country].cca2;
                await signUp(
                  values.password,
                  countryName,
                  countryCode,
                  values.arrival,
                  values.permitExpiry,
                  userForm
                );
                actions.setSubmitting(false);
                setSignupFlow("confirm");
              } catch (err) {
                actions.setSubmitting(false);
                setSignupError(err.message);
              }
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing="5">
                  <Field name="email" type="email">
                    {({ field, form }) => (
                      <FormControl isDisabled>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input {...field} placeholder="Email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl isDisabled>
                        <FormLabel>First Name</FormLabel>
                        <Input {...field} placeholder="First name" />
                        <FormErrorMessage>
                          {form.errors.firstName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl isDisabled>
                        <FormLabel>Last Name</FormLabel>
                        <Input {...field} placeholder="Last name" />
                        <FormErrorMessage>
                          {form.errors.lastName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="country">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.country && form.touched.country}
                      >
                        <FormLabel>Country</FormLabel>
                        <Select {...field} placeholder="Select country">
                          {countries.map((country, i) => (
                            <option key={i} value={i}>
                              {country.name.common}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {form.errors.country}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="arrival">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.arrival && form.touched.arrival}
                      >
                        <FormLabel>Confirmed Arrival In Canada</FormLabel>
                        <SingleDatepicker
                          {...field}
                          date={arrivalDate}
                          onDateChange={setArrivalDate}
                        />
                      </FormControl>
                    )}
                  </Field>

                  <Field name="permitExpiry">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.permitExpiry && form.touched.permitExpiry
                        }
                      >
                        <FormLabel>Study Permit Expiry Date</FormLabel>
                        <SingleDatepicker
                          {...field}
                          date={expiryDate}
                          onDateChange={setExpiryDate}
                        />
                      </FormControl>
                    )}
                  </Field>

                  <PasswordField />

                  <Button type="submit" isLoading={props.isSubmitting}>
                    Sign up
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </Stack>
  );
};
const Greeting = ({ signupFlow }) => {
  let message = "Please enter your invitation code";
  if (signupFlow === "signup") {
    message = "Confirm your information and create password";
  } else if (signupFlow === "confirm") {
    message =
      "We sent you a confirmation link to your email. Please come back to this page after confirming";
  }
  return (
    <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
      <Text color="fg.muted" fontSize="lg">
        {message}
      </Text>
    </Stack>
  );
};

const Flow = ({ signupFlow, setSignupFlow }) => {
  const [userForm, setUserForm] = useState({});
  const navigate = useNavigate();

  switch (signupFlow) {
    case "signup":
      return <SignupForm userForm={userForm} setSignupFlow={setSignupFlow} />;
    case "confirm":
      return (
        <Button
          as="a"
          colorScheme="yellow"
          marginTop="8px"
          onClick={() => navigate("/login")}
        >
          Login to your account
        </Button>
      );
    default:
      return (
        <SignupCode setSignupFlow={setSignupFlow} setUserForm={setUserForm} />
      );
  }
};

const Signup = () => {
  const [signupFlow, setSignupFlow] = useState("code");

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6" alignItems="center">
          <Logo w="100px" />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "sm", md: "m" }}>Welcome</Heading>
          </Stack>
        </Stack>
        <Greeting signupFlow={signupFlow} />

        <Stack
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg.surface" }}
          boxShadow={{ base: "none", sm: "md" }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Flow signupFlow={signupFlow} setSignupFlow={setSignupFlow} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default Signup;
