import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  // HStack,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import React, { forwardRef, useRef } from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Field } from "formik";

const PasswordField = forwardRef((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({
        preventScroll: true,
      });
    }
  };
  return (
    <Field name="password">
      {({ field, form }) => (
        <FormControl
          isRequired
          isInvalid={form.errors.password && form.touched.password}
        >
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup>
            <InputRightElement>
              <IconButton
                variant="text"
                aria-label={isOpen ? "Mask password" : "Reveal password"}
                icon={isOpen ? <HiEyeOff /> : <HiEye />}
                onClick={onClickReveal}
              />
            </InputRightElement>
            <Input
              id="password"
              ref={mergeRef}
              type={isOpen ? "text" : "password"}
              autoComplete="current-password"
              required
              {...field}
              {...props}
            />
          </InputGroup>
          <FormErrorMessage>{form.errors.password}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
});
PasswordField.displayName = "PasswordField";

export default PasswordField;
