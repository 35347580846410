// import { API } from "aws-amplify";
import axios from "axios";

const clientsAPI = "clients";
const quotesAPI = "quotes";
const headers = {
  response: true,
  headers: { "Content-Type": "application/json" },
};

export function accessCode(email, code) {
  // return API.get(clientsAPI, "/access-code", {
  //   ...headers,
  //   queryStringParameters: {
  //     email,
  //     "access-code": code,
  //   },
  // });
  return new Promise();

}

export function quotes() {
  // return API.get(quotesAPI, "/quotes", {
  //   ...headers,
  // });
  return new Promise();
}

export function getCountries() {
  return axios.get("https://restcountries.com/v3.1/all?fields=name,cca2");
}
