const customKeys = {
  firstName: "given_name",
  lastName: "family_name",
  email: "email",
  phoneNumber: "custom:phoneNumber",
  countryOrg: "custom:countryOrg",
  countryOrgCode: "custom:countryOrgCode",
  city: "custom:city",
  departureDate: "custom:departureDate",
  arrivalDate: "custom:arrivalDate",
  type: "custom:type",
  status: "custom:status",
  relativesThere: "custom:relativesThere",
};

const customStudent = {
  university: "custom:university",
  degree: "custom:degree",
  accomodationFound: "custom:accomodationFound",
  parentsInvolved: "custom:parentsInvolved",
  permitExpiry: "custom:permitExpiryDate",
};

const customImm = {
  alone: "custom:alone",
  career: "custom:career",
  needs: "custom:needs",
};

const parsedKeys = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  phoneNumber: "phoneNumber",
  countryOrg: "countryOrg",
  countryOrgCode: "countryOrgCode",
  city: "city",
  departureDate: "departureDate",
  arrivalDate: "arrival",
  type: "type",
  status: "status",
  relativesThere: "relativesThere",
};

const parsedStudent = {
  university: "university",
  degree: "degree",
  accomodationFound: "accomodationFound",
  parentsInvolved: "parentsInvolved",
  permitExpiry: "permitExpiry",
};

const parsedImm = {
  alone: "alone",
  career: "career",
  needs: "needs",
};

function toAPIconvert(s) {
  if (s === true || s === false) return s.toString(); // boolean to string
  else if (!isNaN(s)) return `${s}`;
  else return s; // number to string, string to string
}

export const userFromApi = (received) => {
  const ret = {};
  Object.keys(parsedKeys).map((key) => (ret[key] = received[customKeys[key]]));
  if (ret.type === "student")
    Object.keys(parsedStudent).map(
      (key) => (ret[key] = received[customStudent[key]])
    );
  else if (ret.type === "immigrant")
    Object.keys(parsedImm).map((key) => (ret[key] = received[customImm[key]]));
  return ret;
};

export const userToApi = (received) => {
  const ret = {};
  Object.keys(parsedKeys).map(
    (key) => (ret[customKeys[key]] = toAPIconvert(received[key]))
  );
  if (received.type === "student")
    Object.keys(parsedStudent).map(
      (key) => (ret[customStudent[key]] = toAPIconvert(received[key]))
    );
  else if (received.type === "immigrant")
    Object.keys(parsedImm).map(
      (key) => (ret[customImm[key]] = toAPIconvert(received[key]))
    );
  return ret;
};

export const month = (i) => {
  const list = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return list[i];
};

export const date = (day) => {
  let res = `${day}`;
  if (day >= 4 && day <= 19) return res + "th";
  else if (day % 10 === 2) return res + "nd";
  else if (day % 10 === 3) return res + "rd";
  else if (day % 10 === 1) return res + "st";
  else return res + "th";
};
