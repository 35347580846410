import { extendTheme } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import "@fontsource/gamja-flower";
import "@fontsource/eb-garamond";

const brand = defineStyle({
  borderWidth: "3px",
  borderStyle: "solid",
  borderColor: "#531f55",
});

const dividerTheme = defineStyleConfig({
  variants: { brand },
});

const theme = {
  fonts: {
    heading: "EB Garamond",
  },
  components: { Divider: dividerTheme },
};

export default extendTheme(theme);
