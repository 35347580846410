import React from "react";
import { Flex } from "@chakra-ui/react";
import MainHeader from "../MainHeader";
import Winnipeg from "./Winnipeg";
import Vancouver from "./Vancouver";
import Toronto from "./Toronto";
import Footer from "../Footer";

const Cities = () => {
  return (
    <Flex direction="column" height="100%">
      <MainHeader variant="info" />
      <Flex as="main" role="main" direction="column" flex="1">
        <Winnipeg />
        <Vancouver />
        <Toronto />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Cities;
