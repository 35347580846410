import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";
import { CircularProgress } from "@chakra-ui/react";
import { userAttributes } from "../functions/auth";

const Protected = ({ redirectTo = "/login", children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    userAttributes()
      .then((res) => {
        setLoading(false);
        setUser(res);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress isIndeterminate />
      ) : user ? (
        React.cloneElement(children, { userAttr: user })
      ) : (
        <Navigate to={redirectTo} replace />
      )}
    </React.Fragment>
  );
};

export default Protected;
