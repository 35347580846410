import React from "react";
import { Image } from "@chakra-ui/react";

const Logo = (props) => {
  return (
    <Image src="/logo.svg" alt="Raven logo" w={props.w} cursor="pointer" />
  );
};

export default Logo;
