import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import Logo from "./Logo";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

const LogoTitle = () => {
  return (
    <React.Fragment>
      <Logo w="100px" />
      <Box padding="4px" textAlign="center" cursor="pointer">
        <Text fontSize="4xl">Raven - Support Network</Text>
        <Text
          fontSize="xs"
          fontFamily="Cardo"
          fontStyle="italic"
          marginTop="-8px"
        >
          #1 community for International Students and Newcomers in Canada
        </Text>
      </Box>
    </React.Fragment>
  );
};

const HeaderVariant = ({ variant }) => {
  const navigate = useNavigate();
  if (variant === "landing") {
    return <LogoTitle />;
  } else {
    return (
      <Flex alignItems="center" onClick={() => navigate("/")}>
        <LogoTitle />
      </Flex>
    );
  }
};

const MainHeader = ({ variant }) => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      fontFamily="EB Garamond"
      justifyContent={variant === "dashboard" ? "center" : "space-between"}
      w="100%"
      paddingLeft="12px"
      paddingRight="32px"
      paddingTop="8px"
      position="sticky"
      top="0"
      bg="white"
      zIndex="100"
      flex="0 0 10%"
    >
      <HeaderVariant variant={variant} />
      {variant !== "dashboard" ? (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />}
            variant="outline"
            marginTop="8px"
          />
          <MenuList fontSize="md">
            <MenuItem>Volunteer with us</MenuItem>
            <MenuItem onClick={() => navigate("/about")}>About us</MenuItem>
            <MenuItem>
              <Button
                as="a"
                colorScheme="yellow"
                w="100%"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </MenuItem>
          </MenuList>
        </Menu>
      ) : null}
    </Flex>
  );
};

export default MainHeader;
