// import { Auth } from "aws-amplify";
import { userToApi } from "./parsings";

export async function signIn(username, password) {
  // return Auth.signIn(username, password);
  return new Promise();
}

export function signUp(
  password,
  countryOrg,
  countryOrgCode,
  arrivalDate,
  permitExpiry,
  moreInfo
) {
  // return Auth.signUp({
  //   username: moreInfo.email,
  //   password,
  //   attributes: userToApi({
  //     ...moreInfo,
  //     countryOrg,
  //     countryOrgCode,
  //     arrivalDate,
  //     permitExpiry,
  //   }),
  // });
  return new Promise();
}

export function confirmSignUp(username, code) {
  // return Auth.confirmSignUp(username, code);
  return new Promise();

}

export function userAttributes() {
  // return Auth.currentAuthenticatedUser();
  return new Promise();

}

export function forgotPassword(username) {
  // return Auth.forgotPassword(username);
  return new Promise();
}

export function forgotPasswordSubmit(username, code, newPassword) {
  // return Auth.forgotPasswordSubmit(username, code, newPassword);
  return new Promise();

}
