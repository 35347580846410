import {
  Avatar,
  AvatarBadge,
  Flex,
  HStack,
  Stack,
  Text,
  Card,
  CardBody,
  Box,
  Icon,
  useColorModeValue,
  createIcon,
} from "@chakra-ui/react";
import React from "react";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
import Flag from "react-world-flags";

const Arrow = createIcon({
  displayName: "Arrow",
  viewBox: "0 0 72 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.600904 7.08166C0.764293 6.8879 1.01492 6.79004 1.26654 6.82177C2.83216 7.01918 5.20326 7.24581 7.54543 7.23964C9.92491 7.23338 12.1351 6.98464 13.4704 6.32142C13.84 6.13785 14.2885 6.28805 14.4722 6.65692C14.6559 7.02578 14.5052 7.47362 14.1356 7.6572C12.4625 8.48822 9.94063 8.72541 7.54852 8.7317C5.67514 8.73663 3.79547 8.5985 2.29921 8.44247C2.80955 9.59638 3.50943 10.6396 4.24665 11.7384C4.39435 11.9585 4.54354 12.1809 4.69301 12.4068C5.79543 14.0733 6.88128 15.8995 7.1179 18.2636C7.15893 18.6735 6.85928 19.0393 6.4486 19.0805C6.03792 19.1217 5.67174 18.8227 5.6307 18.4128C5.43271 16.4346 4.52957 14.868 3.4457 13.2296C3.3058 13.0181 3.16221 12.8046 3.01684 12.5885C2.05899 11.1646 1.02372 9.62564 0.457909 7.78069C0.383671 7.53862 0.437515 7.27541 0.600904 7.08166ZM5.52039 10.2248C5.77662 9.90161 6.24663 9.84687 6.57018 10.1025C16.4834 17.9344 29.9158 22.4064 42.0781 21.4773C54.1988 20.5514 65.0339 14.2748 69.9746 0.584299C70.1145 0.196597 70.5427 -0.0046455 70.931 0.134813C71.3193 0.274276 71.5206 0.70162 71.3807 1.08932C66.2105 15.4159 54.8056 22.0014 42.1913 22.965C29.6185 23.9254 15.8207 19.3142 5.64226 11.2727C5.31871 11.0171 5.26415 10.5479 5.52039 10.2248Z"
      fill="currentColor"
    />
  ),
});

const About = () => {
  return (
    <Flex direction="column" height="100%">
      <MainHeader variant="info" />
      <Flex as="main" role="main" direction="column" flex="1">
        <Stack
          bg="#ebe8f1"
          padding="32px"
          fontFamily="Garamond"
          fontSize="xl"
          marginTop="8px"
          alignItems="flex-start"
          spacing={3}
        >
          <Text fontWeight="bold" fontSize="3xl">
            Our Mission
          </Text>
          <Text>
            Our mission is to ensure you and your family settle down peacefully
            and without being exploited. We will work closely with you as you
            begin to prepare for your journey to Canada and when you arrive
            here.
          </Text>
          <Text>
            We celebrate your decision to move to a new country and begin a new
            life; whether this is for your studies or for better career exposure
            and lifestyle. It is an exciting opportunity, and we love to prepare
            with you for the adventure ahead.
          </Text>
        </Stack>
        <Stack
          bg="#EFEFEF"
          padding="32px"
          fontFamily="Garamond"
          fontSize="xl"
          marginTop="8px"
          alignItems="flex-start"
          spacing={4}
        >
          <Text fontWeight="bold" fontSize="3xl">
            Our Founders
          </Text>
          <Stack
            direction={{ base: "column", lg: "row" }}
            spacing={4}
            alignItems="center"
          >
            <Card
              variant="elevated"
              w={{ base: "300px", lg: "600px" }}
              alignItems="center"
            >
              <CardBody>
                <HStack>
                  <Avatar name="Victoria Nwabuisi" size="2xl" src="/vicky.jpeg">
                    <AvatarBadge boxSize="1em" bg="white">
                      <Flag width="25px" code="ng" />
                    </AvatarBadge>
                  </Avatar>
                  <Box>
                    <Icon
                      as={Arrow}
                      color={useColorModeValue("gray.800", "gray.300")}
                      w={71}
                      transform="scale(1,-1)"
                    />
                    <Text
                      fontSize="lg"
                      fontFamily="Caveat"
                      right="-125px"
                      top="-15px"
                      transform="rotate(-10deg)"
                    >
                      Victoria, CEO
                    </Text>
                  </Box>
                </HStack>
                <HStack>
                  <Box>
                    <Icon
                      as={Arrow}
                      color={useColorModeValue("gray.800", "gray.300")}
                      w={71}
                      transform="rotate(180deg)"
                      position="relative"
                      left="10"
                    />
                    <Text
                      fontSize="lg"
                      fontFamily="Caveat"
                      right="-125px"
                      top="-15px"
                      transform="rotate(10deg)"
                    >
                      Christian, CTO
                    </Text>
                  </Box>
                  <Avatar name="Christian Melendez" size="2xl" src="/chris.jpg">
                    <AvatarBadge boxSize="1em" bg="white">
                      <Flag width="25px" code="pe" />
                    </AvatarBadge>
                  </Avatar>
                </HStack>
              </CardBody>
            </Card>
            <Stack>
              <Text>
                The immigrant experience is unique and packed with untold
                stories of triumph, loss and everything in-between. More than 10
                years ago, we both came to Canada; Victoria as an international
                student and Christian as a Permanent Resident with his family.
              </Text>
              <Text>
                We understand and have lived experiences of the benefits and
                difficulties of moving abroad. It was a special blessing to be
                supported by many genuine individuals during the hardest season
                of the initial settlement.
              </Text>
              <Text>
                This is the bedrock of the <b>Raven - Support Network</b>; to
                provide genuine and tailored settlement for new immigrants and
                students.
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default About;
