import React, { useState } from "react";
import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Input,
  IconButton,
  Stack,
  Text,
  VisuallyHidden,
  useColorModeValue,
  AlertDialog,
  AlertDialogOverlay,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import Logo from "./Logo";
import { EmailIcon } from "@chakra-ui/icons";
import { BiMailSend } from "react-icons/bi";
import { FaLinkedin, FaYoutube } from "react-icons/fa";
import PrivacyPolicy from "./PrivacyPolicy";
import { useNavigate } from "react-router-dom";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      target="_blank"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight="500" fontSize="lg" mb={2}>
      {children}
    </Text>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const [alertDialog, setAlertDialog] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  return (
    <Box
      as="footer"
      role="contentinfo"
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW="6xl" py={8}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo w="100px" />
            </Box>
            <Text fontSize="sm">
              &copy; {new Date().getFullYear()} Raven. All rights reserved.
            </Text>
            <Stack direction="row" spacing={6}>
              <SocialButton label="Email" href="mailto:support@elijahsraven.ca">
                <EmailIcon />
              </SocialButton>
              <SocialButton
                label="YouTube"
                href="https://www.youtube.com/@Raven-SupportNetwork"
              >
                <FaYoutube />
              </SocialButton>
              <SocialButton
                label="LinkedIn"
                href="https://www.linkedin.com/company/raven-support-network/"
              >
                <FaLinkedin />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align="flex-start" fontSize="lg">
            <ListHeader>Company</ListHeader>
            <Link onClick={() => navigate("/about")}>About us</Link>
            <Link href="mailto:support@elijahsraven.ca" isExternal>
              Contact us
            </Link>
            <Link onClick={() => navigate("/testimonials")}>Testimonials</Link>
          </Stack>
          <Stack align="flex-start" fontSize="lg">
            <ListHeader>Support</ListHeader>
            <Link href="#">Legal</Link>
            <Link onClick={onOpen}>Privacy Policy</Link>
            <Link href="#">Terms of Service</Link>
          </Stack>
          <Stack align="flex-start">
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction="row">
              <Input
                placeholder="Your email address"
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
              />
              <IconButton
                as="a"
                bg={useColorModeValue("green.400", "green.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "green.600",
                }}
                aria-label="Subscribe"
                href="mailto:support@elijahsraven.ca"
                icon={<BiMailSend />}
              />
            </Stack>
          </Stack>
        </SimpleGrid>
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          scrollBehavior="inside"
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
          <PrivacyPolicy />
        </AlertDialog>
      </Container>
    </Box>
  );
};

export default Footer;
