import React from "react";
import { Flex, HStack, Text, Link } from "@chakra-ui/layout";
import { date, month } from "../../functions/parsings";
import { Skeleton } from "@chakra-ui/react";

const MainDashboard = ({ arrivalDate, permitExpiry, quote, loadQuote }) => {
  const today = new Date();
  const arrival = new Date(arrivalDate);
  const permit = new Date(permitExpiry);
  const daysIn = Math.ceil((today - arrival) / (1000 * 60 * 60 * 24));

  var firstTitle = "Wohoo! ";
  var secondTitle = Math.abs(daysIn) === 1 ? "day" : "days";

  if (daysIn < 0) {
    firstTitle += "Countdown to your arrival in";
    secondTitle += " to ";
  } else {
    firstTitle += "You've spent,";
    secondTitle += " in ";
  }
  secondTitle += "Canada";

  return (
    <React.Fragment>
      <Flex
        direction="column"
        bg="#E4E6ED"
        border="6px solid #E7E4ED"
        padding="16px"
        fontFamily="Gamja Flower"
        margin="16px"
        marginBottom="8px"
      >
        <Text fontSize="4xl">{firstTitle}</Text>
        <HStack alignItems="baseline">
          <Text as="b" color="red" fontSize="7xl">
            #
          </Text>
          <Text as="b" fontSize="7xl">
            {Math.abs(daysIn)}
          </Text>
          <Text fontSize="4xl">{secondTitle}</Text>
        </HStack>
        <Text>
          Permit Renewal due on: {month(permit.getMonth())}{" "}
          {date(permit.getDate())}, {permit.getFullYear()}
        </Text>
      </Flex>
      <Skeleton
        startColor="#fdf9e1"
        endColor="#ede9d3"
        margin="16px"
        marginTop="8px"
        marginBottom="8px"
        isLoaded={!loadQuote}
      >
        <Flex
          direction="column"
          bg="#fdf9e1"
          padding="16px"
          fontFamily="Garamond"
          fontSize="2xl"
          textAlign="center"
        >
          <Text>Mental Health - Quote of the Week</Text>
          <Text>"{quote}"</Text>
          <Text fontSize="md">
            Need something? anything? please email{" "}
            <Link href="mailto:support@elijahsraven.ca">
              support@elijahsraven.ca
            </Link>
          </Text>
        </Flex>
      </Skeleton>
      <Flex
        direction="column"
        bg="#ebe8f1"
        padding="16px"
        fontFamily="Gamja Flower"
        margin="16px"
        marginTop="8px"
      >
        <HStack>
          <Text fontSize="6xl">&#128075;</Text>
          <Text>Say a warm hello to a new community member...</Text>
        </HStack>
      </Flex>
      <Text fontFamily="Garamond" fontSize="md" textAlign="center">
        &#128161; It’s a small community here, take a moment to connect with
        others and <b>build</b> your community.
      </Text>
    </React.Fragment>
  );
};

export default MainDashboard;
